<template>
  <div class="wrapper1">
    <el-row :gutter="20" type="flex" style="position: relative">
      <el-col :span="3">
        <el-input v-model="keyWord" placeholder="请输入关键字"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" icon="el-icon-search">搜索</el-button>
      </el-col>
      <el-col :span="3">
        <el-button @click="refresh" icon="el-icon-refresh" type="warning"
          >刷新</el-button
        >
      </el-col>
      <el-col :span="4" style="position: absolute; right: 180px">
        <el-select
          v-model="selectUsersDk"
          collapse-tags
          style="margin-left: 20px"
          placeholder="发货操作员"
          :disabled="isRow"
        >
          <el-option
            v-for="item in usersDk"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="position: absolute; right: 0">
        <el-button type="primary" @click="sureSelectDk">确定指派</el-button>
      </el-col>
    </el-row>
    <table-container
      :list="batchList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="批次名"> </el-table-column>
      <el-table-column prop="image_num" label="图片数量"> </el-table-column>
      <el-table-column prop="order_item_num" label="订单项数量">
      </el-table-column>
    </table-container>
  </div>
</template>

<script>
export default {
  name: "curst",
  components: {},
  props: {},
  inject: ["reload"],
  data() {
    return {
      batchList: [],
      keyWord: "",
      curBatchId: "",
      selectBatchs: [],
      usersDk: [],
      selectUsersDk: [],
    };
  },
  watch: {},
  computed: {
    isRow() {
      return this.selectBatchs.length == 0 ? true : false;
    },
  },
  methods: {
    //选择批次交付操作员
    handleSelectionChange(data) {
      this.selectBatchs = data;
    },
    //选择操作员交付
    async sureSelectDk() {
      if (this.selectBatchs.length == 0) {
        this.$message({
          message: "批次未选!",
          type: "warning",
        });
        return;
      }
      if (this.selectUsersDk.length == 0) {
        this.$message({
          message: "操作员未选择!",
          type: "warning",
        });
        return;
      }
      let arr = this.selectBatchs.map((item, index) => {
        return item.id;
      });
      let str = arr.toString();
      let data = await this.$api.flowApi.assignFhOperator({
        batch_ids: str,
        op_id: this.selectUsersDk,
      });
      this.refresh();
      if (data.code != 0) {
        this.$message({
          message: "指派失败",
          type: "error",
        });
        return;
      }
    },
    //刷新
    refresh() {
      this.reload();
    },
  },
  async created() {
    //获取代打壳批次文件
    let data = await this.$api.flowApi.getWaitingForFH();

    if (data.code != 0) {
      this.$message({
        message: "批次列表获取失败",
        type: "error",
      });
      return;
    }
    this.batchList = data.batches;
    //获取某种角色的操作员列表
    let data1 = await this.$api.flowApi.getAdminsByRole({ role_type: 6 });
    if (data1.code != 0) {
      this.$message({
        message: "打壳操作员获取失败",
        type: "error",
      });
      return;
    }
    this.usersDk = data1.users;
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper1 {
  /deep/.el-pagination {
    text-align: left;
  }
  /deep/.el-popover__reference {
    border: none;
    padding: 0;
    margin: 0;
    color: #3a8ee6;
  }
}
</style>
